import { render, staticRenderFns } from "./cateDetail.vue?vue&type=template&id=7ab9c610&scoped=true&"
import script from "./cateDetail.vue?vue&type=script&lang=js&"
export * from "./cateDetail.vue?vue&type=script&lang=js&"
import style0 from "./cateDetail.vue?vue&type=style&index=0&id=7ab9c610&scoped=true&lang=css&"
import style1 from "./cateDetail.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ab9c610",
  null
  
)

export default component.exports
import {QTable} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable})
