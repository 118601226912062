//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
export default {
  name: "moreSaleData",
  data() {
    return {
      category: "",
      type: "",
      categoryData: [],
      rawCondition: "",
      thisNumSort: [],

      thisMoneySort: [],

      allNumSort: [],

      allMoneySort: [],
      typeDesc: "",
      categorySaleLoading: false,
      imageBase: "https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/stockcheck/",
    };
  },
  created() {
    this.category = this.$route.query.category;
    this.type = this.$route.query.type;
    this.getLocalCategorySaleData();
  },
  methods: {
    getLocalCategorySaleData() {
      const that = this;
      if (this.type == "all") {
        this.rawCondition = JSON.parse(LocalStorage.getItem("rawCondition"));
        this.typeDesc = "全国";
      } else {
        this.rawCondition = JSON.parse(
          LocalStorage.getItem("rawLocalCondition")
        );
        this.typeDesc = "本店";
      }

      this.rawCondition.push({
        SIGN: "I",
        OPTION: "EQ",
        PARAMETER: "Z0MATERIAL__ZCPL_CMOO_002",
        LOW: this.category,
        HIGH: "",
        LOW_FLAG: "1",
      });
      this.categorySaleLoading = true;
      console.log(this.categorySaleLoading);
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/bw/BwApi/qywx_getCategoryProportion",
          this.rawCondition
        )
        .then((res) => {
          if (res && res.data && res.data.data) {
            this.categoryData = this.dataProcessing(res.data.data);
            this.categoryData = res.data.data;
            this.typeDesc += this.categoryData[0].category_str;
            console.log(this.categoryData);
          } else {
            that.categoryData = [];
          }
          that.categorySaleLoading = false;
        });
    },
    dataProcessing(rawCategoryData) {
      for (let i = 0; i < rawCategoryData.length; i++) {
        rawCategoryData[i]["imgSrc"] =
          this.imageBase +
          rawCategoryData[i].zpp +
          "/" +
          rawCategoryData[i].seasonName
            .replace("A", "X")
            .replace("B", "X")
            .replace("C", "Y")
            .replace("D", "Y") +
          "/" +
          rawCategoryData[i].temp_spu +
          ".jpg";
      }
      rawCategoryData.sort((a, b) => {
        return b.paidAmount - a.paidAmount;
      });
      console.log("rawCategoryData", rawCategoryData);
      return rawCategoryData;
    },
  },
  activated() {
    window.document.title = "门店业绩";
  },
};
